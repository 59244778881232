:root {
  --main-bg-color: #eee;
  --main-fg-color: #000;
  --title-bg-color: #CCC;
  --button-color: #48F;
  --link-color: #36C;
  --code-area-bg-color: #CCC;
  --un-run-bg-color: #8AF;
  --aborted-bg-color: #DDD;
  --fade-bg-color: rgba(255, 255, 255, 0.9);
}
html {
  box-sizing: border-box;
  font-family: sans-serif;
  line-height: 1.5;
  background: var(--main-bg-color);
  color: var(--main-fg-color);
}
html, body {
  height: 100%;
}
*, *:before, *:after {
  box-sizing: inherit;
}
/* ---------------- */
#root {
  height: 100%;
  overflow: auto;
}
.head {
  display: flex;
  font-size: larger;
  font-weight: bold;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}
.head a {
  text-decoration: none;
  color: var(--main-fg-color);
  display: flex;
}
.head img {
  height: 1em;
  vertical-align: middle;
  margin: 5px;
}
.head .beta {
  font-size: x-small;
  vertical-align: top;
  position: relative;
  right: 1em;
}
a {
  color: var(--link-color);
}
.blocked {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.abort {
  margin-top: 3em;
  text-align: center;
  background: var(--main-bg-color);
  padding: 1em;
}
.abort button {
  margin-top: 1em;
}
.dialog {
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 100;
  width: 100%;
  height: 100%;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}
.dialog>* {
  background: var(--main-bg-color);
  border-radius: 5px;
  padding: 1em;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  width: 800px;
  outline: none;
}
.fixed-size-dialog>* {
  min-height: 90%;
}
.dialog-heading {
  display: flex;
  justify-content: space-between;
  font-size: xx-large;
  font-weight: bold;
  margin: 10px;
  position: relative;
  user-select: none;
}
.dialog-content {
  margin: 10px;
}
.dialog-close {
  position: absolute;
  right: 0;
  top: 0;
  vertical-align: top;
}
.dialog pre {
  width: 100%;
  overflow: auto;
  background: #222;
  color: #eee;
  padding: 1em;
}
.save-as-gist-pat {
  display: flex;
  align-items: center;
}
.save-as-gist-pat>* {
  vertical-align: middle;
}
.save-as-gist-pat>div:nth-child(1) {
  flex: 0 0 auto;
}
.save-as-gist-pat>div:nth-child(2) {
  flex: 1 1 auto;
}
.section {
  margin-bottom: 10px;
  border: 1px solid #888;
  border-radius: 5px;
  padding: 1em;
}
.section-heading {
  font-size: large;
  border-bottom: 1px solid #888;
  margin-bottom: 1em;
}
.bookmark {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}
.disabled {
  opacity: 0.2;
  pointer-events: none;
  cursor: not-allowed;
}
.top,
.bottom {
  display: flex;
  padding: 10px 10px 0 10px;
  position: relative;
}
.top>div {
  flex: 1 1 50%;
}
.top .right {
  display: flex;
  margin-left: 10px;
  justify-content: space-between;
  overflow: hidden;
}
.toolbar {
  display: flex;
}
.left {
  position: relative;
}
/* -------------- */
.split {
  width: 100%;
}
.split, .gutter.gutter-horizontal {
    float: left;
}
.gutter {
    background-color: var(--main-bg-color);
}
.gutter.gutter-horizontal,
.gutter.gutter-horizontal:hover {
    cursor: ew-resize;
}
.gutter.gutter-vertical,
.gutter.gutter-vertical:hover {
    cursor: ns-resize;
}
.gutter.gutter-vertical {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=');
    background-repeat: no-repeat no-repeat;
    background-position: center center;
}
.gutter.gutter-horizontal {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
    background-repeat: no-repeat no-repeat;
    background-position: center center;
}
.resize-handle-horizontal {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=');
    background-repeat: no-repeat no-repeat;
    background-position: center center;
}
/* ------------ */
.messages {
  position: fixed;
  bottom: 0;
  z-index: 1000;
}
.messages>div {
  background: #888;
  color: white;
  padding: 0.5em;
  margin: 0.25em;
  border-radius: 0.25em;
}
.messages .error {
  background: #C00;
}
.messages .info {
  background: #00F;
}
input {
  border: none;
  padding: 0.25em;
  background: var(--title-bg-color);
  color: var(--main-fg-color);
}
.name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.name > * + * {
  margin-left: 10px;
}
.name>*:nth-child(1) {
  flex: 1 1 auto;
}
.avatar {
  height: 2em;
  border-radius: 100%;
}
input[type="text"],
input[type="password"] {
  outline: none;
  width: 100%;
  font-size: large;
}
.charts {
  margin-top: 1em;
}
.named {
  padding: 0.25em;
}
.code-area {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5em;
  flex: 1 1 auto;
  background: var(--code-area-bg-color);
}
.code-area button {
  margin-right: 0;
}
.code-area input {
  background: var(--code-area-bg-color);
  font-size: medium;
}
.expander {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}
.hider {
  width: 1em;
  color: #888;
}
.hidee {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
}
.hidee>* {
  flex: 1 1 auto;
}
.hidee-hide {
  height: 0;
  overflow: hidden;
}
button {
  background: none;
  border: 2px solid var(--button-color);
  color: var(--main-fg-color);
  border-radius: 0.25em;
  padding: 0.25em;
  margin-right: 0.25em;
  min-width: 1.5em;
  font-weight: bold;
}
button:hover {
  background: var(--button-color);
}

.react-codemirror2 {
  position: relative;
  height: 100%;
}
.CodeMirror {
  width: 100%;
  height: 100%;
}
.CodeMirror-overlayscroll-horizontal div,
.CodeMirror-overlayscroll-vertical div {
  opacity: 0.6;
}
.markdown code {
  background: var(--title-bg-color);
  padding: 0.25em;
  border-radius: 0.5em;
  line-height: 2;
}
.markdown pre>code {
  line-height: 1;
}
.gists {
  background-color: rgba(0, 0, 255, 0.1);
  width: 100%;
  border: none;
}
.gists tr:nth-child(2n) {
  background-color: rgba(128, 160, 255, 0.2);
}
.gists td:nth-child(3n) {
  text-align: right;
  white-space: nowrap;
}

.comment-notes {
  padding: 1em;
  margin: 1em;
  background: rgba(255, 255, 255, 0.5);
  color: red;
}
.comments,
.comment-sep {
    margin: 0 auto 1em;
    max-width: 700px;
    width: calc(100% - 40px);
}
.comments iframe {
  width: 100%;
  border: none;
}
.comments .links {
  text-align: center;
}
hr {
  border: none;
  background: var(--title-bg-color);
  height: 2px;
}
/*-------------*/
.logout {
  display: flex;
  align-items: center;
}
/*-------------*/
.new-comment {
  border-radius: 0.5em;
  border: 1px solid var(--code-area-bg-color);
  margin-left: calc(0.5em + 42px);
  margin-right: 0.5em;
  margin-bottom: 15em;
  position: relative;
}
.new-comment .new-comment-head {
  background: var(--code-area-bg-color);
  padding: 0.5em;
}
.new-comment .new-comment-head .user-avatar {
  position: absolute;
  left: 0;
  top: 0;
  transform: translateX(-42px);
}
.new-comment .react-codemirror2 {
  padding: 0 0.5em 0.5em 0.5em;
  height: 10em;
  resize: vertical;
  overflow: auto;
}
.new-comment .new-comment-preview {
  min-height: 10em;
  padding: 0.5em;
}
.new-comment .new-comment-tabs {
  margin-top: 1em;
}
.new-comment input[type="radio"] {
  opacity: 0;
  width: 0;
}
.new-comment input + label {
  padding: 0.5em 0.5em 0 0.5em;
  margin-right: 0.125em;
  background: rgba(0,0,0,0.1);
}
.new-comment input:checked + label {
  background: var(--editor-bg-color);
}
.new-comment-submit {
    display: flex;
    justify-content: flex-end;
    padding-top: 1em;
}

@media (max-width: 740px) {
  .top,
  .bottom {
    flex-direction: column;
    padding-top: 5px;
  }
  .top {
    flex-direction: column-reverse;
  }
  .left {
    width: 100%;
  }
  .right {
    margin-left: 0;
    margin-bottom: 5px;
  }
  .top .right {
    padding-top: 5px;
    margin-left: 0;
  }
 .head {
    font-size: medium;
    padding: 5px 10px 5px 10px;
  }
  .head .beta {
    font-size: xx-small;
  }

}

@media (prefers-color-scheme: dark) {
  :root {
    --main-bg-color: #444;
    --main-fg-color: #fff;
    --title-bg-color: #222;
    --button-bg-color: #fff;
    --link-color: #6CF;
    --code-area-bg-color: #555;
    --un-run-bg-color: blue;
    --aborted-bg-color: #000;
    --fade-bg-color: rgba(0, 0, 0, 0.5);
  }
  .CodeMirror-overlayscroll-horizontal div,
  .CodeMirror-overlayscroll-vertical div {
    opacity: 0.3;
  }
  .comment-notes {
    background: rgba(0, 0, 0, 0.5);
  }
  .gists {
    background-color: rgba(0, 0, 0, 0.3);
  }
  .gists tr:nth-child(2n) {
    background-color: rgba(0, 0, 0, 0.2);
  }
}